const cardIsVisible = el => {
  const { top, left, bottom, right } = el.getBoundingClientRect()
  const { screenY, innerHeight } = window
  return top >= screenY && bottom <= screenY + innerHeight
}

const animate = () => {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  for (const el of document.querySelectorAll('.card')) {
    const row = el.closest(".row")
    if (row.classList.contains('animate')) {
      continue
    }

    if (!cardIsVisible(el, true)) {
      row.classList.add('gray-cover')
      continue
    }

    row.classList.remove('gray-cover')
    row.classList.add('animate')
  }
}

addEventListener('scroll', ev => {
  animate()
})
animate()
