import * as CookieConsent from 'vanilla-cookieconsent'

CookieConsent.run({

  // root: 'body',
  // autoShow: true,
  // disablePageInteraction: true,
  // hideFromBots: true,
  mode: 'opt-out',
  // revision: 0,

  cookie: {
    name: 'cc',
    domain: location.hostname,
    path: '/',
    sameSite: 'Lax',
    expiresAfterDays: 365
  },

  // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'top left',
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false
    }
  },

  onFirstConsent({ cookie }) {
    updateConsent(cookie)
  },

  onConsent({ cookie }) {
    updateConsent(cookie)
    // console.log('onConsent fired!', cookie)
  },

  onChange({ cookie, changedCategories, changedServices }) {
    updateConsent(cookie)
    // console.log('onChange fired!', changedCategories, changedServices)
  },

  onModalReady({ modalName }) {
    // console.log('ready:', modalName)
  },

  onModalShow({ modalName }) {
    // console.log('visible:', modalName)
  },

  onModalHide({ modalName }) {
    // console.log('hidden:', modalName)
  },
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
      services: {
        cc: {
          label: 'Cookie Preferences',
          onAccept() {},
          onReject() {}
        },
        aft: {
          label: 'Forms Antiforgery',
          onAccept() {},
          onReject() {}
        }
      }
    },
    analytics: {
      enabled: true,
      autoClear: {
        cookies: [
          {
            name: /^_ga/ // regex: match all cookies starting with '_ga'
          }
        ],
        reloadPage: true
      },

      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
      services: {
        ga: {
          label: 'Google Analytics',
          onAccept() {},
          onReject() {}
        }
      }
    },
    ads: {}
  },

  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: 'We use cookies',
          description: 'We use some necessary cookies for essential website functionality. Some optional cookies are used to understand how you use our website, and improve it, but you can always reject them via the "Manage" option',
          acceptAllBtn: 'Accept',
          // acceptNecessaryBtn: 'Reject',
          showPreferencesBtn: 'Manage',
          // closeIconLabel: 'Reject all and close modal',
          footer: '<a href="/privacy" target="_blank">Privacy Policy</a>'
        },
        preferencesModal: {
          title: 'Manage cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Accept current selection',
          closeIconLabel: 'Close modal',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Your Privacy Choices',
              description: 'In this panel you can express some preferences related to the processing of your personal information. To deny your consent to non necessary processing activities, switch the toggles to off or use the "Reject all" button and confirm you want to save your choices.'
            },
            {
              title: 'Strictly Necessary',
              description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
              linkedCategory: 'necessary',
              cookieTable: {
                caption: 'Cookie table',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Description'
                },
                body: [
                  {
                    name: '.AspNetCore.Antiforgery.*',
                    domain: location.hostname,
                    desc: 'Antiforgery form validation token'
                  },
                  {
                    name: 'cc',
                    domain: location.hostname,
                    desc: 'User cookies preference'
                  }
                ]
              }
            },
            {
              title: 'Performance and Analytics',
              description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
              linkedCategory: 'analytics',
              cookieTable: {
                caption: 'Cookie table',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Description'
                },
                body: [
                  {
                    name: '_ga*',
                    domain: location.hostname,
                    desc: 'Anaytics visitor tracking'
                  }
                ]

              }
            },
            {
              title: 'More information',
              description: 'For any queries in relation to my policy on cookies and your choices, please <a href="/contact">contact us</a>'
            }
          ]
        }
      }
    }
  }
})

function updateConsent(cookie) {
  const gtmScripts = Array.prototype.filter.call(document.getElementsByTagName('script'), el => el.src.includes('googletagmanager'))

  if (!cookie.categories.includes('analytics') && gtmScripts.length > 0) {
    gtag('consent', 'update', {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied'
    })
    return
  }

  if (cookie.categories.includes('analytics') && gtmScripts.length === 0) {
    gtag('consent', 'update', {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'granted'
    })
  }
}

const gtmScript = document.createElement('script')
gtmScript.async = true
gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-5WKN2R'
gtmScript.id = 'gtm_script'
const firstScript = document.getElementsByTagName('script')[0]
firstScript.parentNode.insertBefore(gtmScript, firstScript)
